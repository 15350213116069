import * as React from 'react'
import { navigate } from 'gatsby'
import { GetBlogUrl } from 'hooks/blog-functions'
import { Label, Input, Button } from 'components/common/InputWithButton'
import { IoIosSearch } from 'react-icons/io'

const BlogSearch = ({ className }) => {
  const [$query, set$query] = React.useState(null)

  const blogUrl = GetBlogUrl()

  function handleChange({ target }) {
    set$query(target.value)
  }

  function handleKeyUp({ key }) {
    if (key !== 'Enter') return
    search()
  }

  function search() {
    !!$query && navigate(`/${ blogUrl }/search?query=${ $query }`)
  }

  return (
    <Label className={className}>
      <Input
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        type="search"
        placeholder="Search"
      />

      <Button onClick={search}>
        <IoIosSearch />
      </Button>
    </Label>
  )
}

export default BlogSearch
