import * as React from 'react'
import * as css from './CategoryAndDate.module.css'

const CategoryAndDate = ({ category, publishDate, formattedPublishDate }) => (
  <div className={css.root}>
    {!!category.length
      ? <span className={css.category}>{category.map(({ title }) => title).join`, `}</span>
      : <span className={css.category}>Uncategoried</span>
    }

    {!!publishDate && <>
      {' / '}
      <time className={css.date} dateTime={publishDate}>{formattedPublishDate}</time>
    </>}
  </div>
)

export default CategoryAndDate
