import * as React from 'react'
import * as css from './ArticlePreview.module.css'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { GetPostUrl } from 'hooks/blog-functions'
import highlight from 'utils/highlight'
import CategoryAndDate from './CategoryAndDate'
import { MdKeyboardArrowRight } from 'react-icons/md'
import clsx from 'clsx'

const ArticlePreview = ({ post, large, query }) => (
  <article className={css.root}>
    <Link className={`${css.link} link`} to={GetPostUrl(post)}>
      {post.thumbnail
        ? (
          <GatsbyImage
            className={clsx(css.img, large && css.large)}
            image={getImage(large ? post.featuredImage.asset : post.thumbnail.asset)}
            alt={post.title}
            objectFit="cover"
          />
        ) : (
          <div className={css.placeholder} />
        )
      }

      <div
        className={clsx(large ? 'h1' : 'h3', 'cps-navy')}
        dangerouslySetInnerHTML={{ __html: highlight(post.title, query) }}
      />

      <CategoryAndDate {...post} />

      {(large && post.seo.description) && (
        <div className={css.desc}>{post.seo.description}</div>
      )}

      {large && (
        <div className={`${css.more} link`}>
          Read more
          <MdKeyboardArrowRight />
        </div>
      )}
    </Link>
  </article>
)


export default ArticlePreview
