import clsx from 'clsx'
import * as React from 'react'
import * as css from './InputWithButton.module.css'

export const Label = ({ className, children }) => (
  <div className={clsx(className, css.root)}>
    {children}
  </div>
)

export const Input = React.forwardRef(({ className, ...props}, ref) => (
  <label>
    <input
      ref={ref}
      className={clsx(className, css.input)}
      {...props}
    />
  </label>
))

export const Button = ({ className, ...props}) => (
  <button
    className={clsx(className, css.btn)}
    {...props}
  />
)
