import { graphql, useStaticQuery } from 'gatsby'

export const GetBlogUrl = () => {
  const { seo } = useStaticQuery(query).sanityBlogSettings
  return seo.slug.current
}

export const GetPostUrl = ({ seo }) => {
  const blogUrl = GetBlogUrl()

  return `/${ blogUrl }/${ seo.slug.current }`
}

const query = graphql`query postUrl {
  sanityBlogSettings {
    seo { slug { current } }
  }
}`
