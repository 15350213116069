import * as React from 'react'
import * as css from './search.module.css'
import { BlogProvider, BlogContext } from 'components/blog/BlogContext'
import DefaultLayout from 'layouts/Default'
import BlogSearch from 'components/blog/BlogSearch'
import ArticlePreview from 'components/blog/ArticlePreview'

const Results = ({ query }) => {
  const { allPosts } = React.useContext(BlogContext)

  const results = allPosts.filter(({ title, body }) => (
    !!query && (
      title.toLowerCase().includes(query.toLowerCase()) ||
      body.some(({ children }) => children.some(({ text }) => text.toLowerCase().includes(query.toLowerCase())))
    )
  ))

  return (
    <section>
      <h2 className={css.stats}>Found {results.length} results(s)</h2>

      {!!results?.length && (
        <ul className={css.list}>
          {results.map((post, key) => (
            <ArticlePreview post={post} query={query} key={key} />
          ))}
        </ul>
      )}
    </section>
  )
}

const BlogSearchPg = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const query = params.get`query`

  return (
    <BlogProvider>
      <DefaultLayout className="container padding">
        <h1 className="h1">Search for "{query}"</h1>

        <section className={css.search}>
          <BlogSearch className={css.inner} />
        </section>

        <Results query={query} />
      </DefaultLayout>
    </BlogProvider>
  )
}

export default BlogSearchPg
