import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const BlogContext = React.createContext(null)

export const BlogProvider = ({ children }) => {
  const {
    sanityBlogSettings,
    allSanityBlogCategory,
    allSanityBlogPost,
  } = useStaticQuery(query)

  const [$selectedCategory, set$selectedCategory] = React.useState(false)
  const [$limit, set$limit] = React.useState(6)

  const value = {
    blog: sanityBlogSettings,
    allCategories: allSanityBlogCategory.nodes,
    allPosts: allSanityBlogPost.nodes,
    $selectedCategory, set$selectedCategory,
    $limit, set$limit,
  }

  return <BlogContext.Provider value={value}>
    {children}
  </BlogContext.Provider>
}

const query = graphql`query BlogContext {
  sanityBlogSettings {
    title
    cta {
      title
      description
      image { asset { gatsbyImageData(placeholder: NONE) } }
    }
  }
  allSanityBlogCategory(sort: {fields: title}) {
    nodes {
      title
      slug { current }
    }
  }
  allSanityBlogPost(sort: {fields: publishDate, order: DESC}) {
    nodes {
      title
      body { children { text } }
      publishDate
      formattedPublishDate: publishDate(formatString: "MMM D, YYYY")
      category {
        title
        slug { current }
      }
      featuredImage {
        asset { gatsbyImageData(placeholder: NONE, aspectRatio: 1.7) }
      }
      thumbnail: featuredImage {
        asset { gatsbyImageData(placeholder: NONE, width: 300, aspectRatio: 1.7) }
      }
      seo {
        description
        slug { current }
      }
    }
  }
}`
